import DataTableRow from './DataTableRow/DataTableRow';
import GhostLoader from 'react-ghost-loader';
import Pagination, { PaginationLoading } from '../Pagination/Pagination';
import React, { useEffect } from 'react';
import { Alert, Card } from '@klover/attain-design-system';
import { ArrowDown, ArrowUp } from '@phosphor-icons/react';
import { TABLE_SORT_TOOLTIP_TEXT } from '../../constants';
import * as Styled from './DataTable.styles';

interface DataTableProps {
  loading?: boolean;
  error?: boolean;
  title?: string | React.ReactNode;
  description?: string;
  columns: {
    title: string | React.ReactNode;
    column: string;
    width?: string | number;
    isSortDisabled?: boolean;
    isHidden?: boolean;
    isSorted?: boolean;
    sortOrder?: 'ASC' | 'DESC';
  }[];
  rows:
    | {
        component: React.ReactNode;
      }[]
    | undefined;
  noFooter?: boolean;
  customActions?: React.ReactNode;
  noBorder?: boolean;
  noDataMessage?: string;
  noPagination?: boolean;
  onPaginationClick?: () => void;
  onRowsPerPageChange?: () => void;
  onSortChange: () => void;
  showRowsPerPageDropdown?: boolean;
  customRowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  totalRows: number;
}

const DataTable = ({
  loading = true,
  error,
  title,
  description,
  columns,
  rows = [],
  noFooter,
  customActions,
  noBorder,
  noDataMessage,
  noPagination,
  onPaginationClick,
  onRowsPerPageChange,
  onSortChange,
  showRowsPerPageDropdown,
  customRowsPerPageOptions,
  defaultRowsPerPage = 5,
  totalRows = 0,
}: DataTableProps) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [offset, setOffset] = React.useState(0);

  useEffect(() => {
    if (!noPagination && onPaginationClick) {
      onPaginationClick(offset);
    }
  }, [offset]);

  useEffect(() => {
    if (!noPagination && onRowsPerPageChange) {
      onRowsPerPageChange(rowsPerPage);
    }
  }, [rowsPerPage]);

  if (error) {
    return (
      <Card>
        <Alert severity="error">Error loading data</Alert>
      </Card>
    );
  }

  if (loading) {
    return (
      <Styled.Wrapper
        $noBorder={noBorder ?? false}
        data-testid="data-table-loading"
      >
        <header>
          <Styled.CardHeader>
            <Styled.CardTitle>
              <GhostLoader height="32px" heightRandom={0} />
            </Styled.CardTitle>
            <GhostLoader
              height="42px"
              heightRandom={0}
              fdsfsd
              width="42px"
              widthRandom={0}
            />
            <GhostLoader
              height="42px"
              heightRandom={0}
              width="42px"
              widthRandom={0}
            />
          </Styled.CardHeader>
          <Styled.CardDesc>
            <GhostLoader height="24px" heightRandom={0} />
          </Styled.CardDesc>
        </header>

        <Styled.TableWrapper $noBorder={noBorder ?? false}>
          <thead>
            <tr>
              {columns.map((_, i) => (
                <th key={i}>
                  <GhostLoader height="20px" heightRandom={0} />
                </th>
              ))}
              <th style={{ width: 1 }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(5)].map((_, i) => (
              <tr key={i}>
                {columns.map((_, j) => (
                  <td key={j}>
                    <GhostLoader height="24px" heightRandom={0} width="100%" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length + 1}>
                <PaginationLoading />
              </td>
            </tr>
          </tfoot>
        </Styled.TableWrapper>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper $noBorder={noBorder ?? false}>
      {title && (
        <Styled.HeaderWrapper>
          <Styled.CardHeader>
            <Styled.CardTitle>{title}</Styled.CardTitle>
            {customActions}
          </Styled.CardHeader>
          {description && <Styled.CardDesc>{description}</Styled.CardDesc>}
        </Styled.HeaderWrapper>
      )}
      {rows.length === 0 ? (
        <p>
          <Alert severity="info">
            {noDataMessage ?? 'No data for this table'}
          </Alert>
        </p>
      ) : (
        <Styled.TableWrapper $noBorder={noBorder ?? false}>
          <thead>
            <tr>
              {columns.map((column, i) => (
                <th
                  title={!column?.isSortDisabled && TABLE_SORT_TOOLTIP_TEXT}
                  style={{
                    width: column.width ?? 'auto',
                    cursor: column?.isSortDisabled ? 'not-allowed' : 'pointer',
                    display: column?.isHidden ? 'none' : '',
                  }}
                  key={i}
                  onClick={() => {
                    if (!column?.isSortDisabled)
                      onSortChange({ column: column.column });
                  }}
                >
                  <span style={{ display: 'flex', gap: 3 }}>
                    {column.title}
                    {column?.isSorted === true ? (
                      column?.sortOrder === 'ASC' ? (
                        <ArrowUp size={18} />
                      ) : (
                        <ArrowDown size={18} />
                      )
                    ) : null}
                  </span>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {rows.map((row, i) => (
              <DataTableRow key={i} row={row} />
            ))}
          </tbody>

          {!noPagination && !noFooter && (
            <tfoot>
              <tr>
                <td colSpan={columns.length + 1}>
                  <Pagination
                    total={totalRows}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    setOffset={setOffset}
                    offset={offset}
                    hideDropdown={!showRowsPerPageDropdown}
                    rowsPerPageOptions={customRowsPerPageOptions}
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </Styled.TableWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default DataTable;
