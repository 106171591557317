import React, { useEffect } from 'react';
import _ from 'lodash';
import { IconButton } from '@klover/attain-design-system';
import { Link, useHistory, useParams } from 'react-router-dom';
import { UploadStates } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';

// Material UI Components
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Page Components
import DraftStateDialog from '../draftStateDialog';
import DuplicateOfferDialog from '../duplicateOfferDialog';
import OfferDeleteDialog from '../offerDeleteDialog';
import OfferDimensions from '../offerDimensions';
import OfferName from '../offerName';
import {
  clearOfferIsSaveable,
  fetchOffer,
  loadOriginalOffer,
  setUIState,
  toggleDraftStateDialog,
  toggleEditOffer,
  updateCurrentOffer,
} from '../slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draftIndicatorAndTotalAudience: {
      display: 'flex',
    },
    draftIndicator: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 10,
    },
    noOffers: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 12,
      boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.07)',
      zIndex: 1,
    },
    dimensions: {
      margin: theme.spacing(2),
    },
    noDimensions: {
      minHeight: '75vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
);

const OfferManager = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { offerid } = useParams();

  const currentGroup = useAppSelector(
    (state) => state.offerWallReducer.currentGroup
  );

  useEffect(() => {
    dispatch(setUIState({ value: false, uiPropertyName: 'errorFetching' }));

    if (offerid) {
      dispatch(
        fetchOffer({
          offerid: offerid,
        })
      );
    } else if (!offerid && currentGroup) {
      const newOffer = {
        label: '',
        offername: '',
        successurl: [null],
        onofferseenurltofire: [null],
        actionforpoints: 'ACTION_CONV',
        placementid: '1',
        devices: 'ALL_DEVICES',
        zipCount: 0,
        zip_upload_status: UploadStates.DONE,
      };
      dispatch(loadOriginalOffer(null));
      dispatch(updateCurrentOffer(newOffer));
    } else {
      history.push('/main/offer-wall-manager');
    }
    return () => {
      dispatch(clearOfferIsSaveable());
      dispatch(setUIState({ value: false, uiPropertyName: 'errorFetching' }));
    };
  }, []);

  const offerLoading = useAppSelector(
    (state) => state.offerWallReducer.ui.offerLoading
  );
  const isOfferLoading = offerLoading.state === 'loading' ? true : false;
  const isOfferDeleting = useAppSelector(
    (state) => state.offerWallReducer.ui.offerDeleteDialog
  );
  const openDraftStateDialog = useAppSelector(
    (state) => state.offerWallReducer.ui.openDraftStateDialog
  );
  const currentOffer = useAppSelector(
    (state) => state.offerWallReducer.currentOffer
  );

  const openDuplicateOfferDialog = useAppSelector(
    (state) => state.offerWallReducer.ui.openDuplicateOfferDialog
  );

  const originalOffer = useAppSelector(
    (state) => state.offerWallReducer.originalOffer
  );

  const errorFetching = useAppSelector(
    (state) => state.offerWallReducer.ui.errorFetching
  );

  const isTheSame =
    currentOffer && originalOffer
      ? _.isEqual(
          _.omit(currentOffer, ['price', 'backgroundimgurl', 'categorytype']),
          _.omit(originalOffer, ['price', 'backgroundimgurl', 'categorytype'])
        )
      : false;
  const handleNavigateToManager = (e) => {
    dispatch(toggleEditOffer(false));
    if (!isTheSame) {
      e.preventDefault();
      dispatch(
        toggleDraftStateDialog({ open: true, path: '/main/offer-wall-manager' })
      );
    }
    history.push(
      `/main/offer-wall-manager?groupId=${currentGroup?.id || 'all'}`
    );
  };

  return (
    <>
      {!errorFetching && (
        <>
          <Grid item container className={classes.root}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={handleNavigateToManager} color="primary">
                <ArrowBackIcon />
              </IconButton>
              <div style={{ marginLeft: 8 }} />
              <OfferName offerId={offerid} />
            </div>
          </Grid>
          {/* Top Divider */}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {isOfferLoading ? <LoadingOffer /> : <OfferDimensions />}
          <DraftStateDialog openDraftStateDialog={openDraftStateDialog} />
          <DuplicateOfferDialog open={openDuplicateOfferDialog} />
          <OfferDeleteDialog isOpen={isOfferDeleting} />
        </>
      )}
      {errorFetching && (
        <Styled.OfferContainer>
          <Styled.OfferUnknown>
            <Styled.OfferUnknownText>
              404 Error: This page does not exist.
            </Styled.OfferUnknownText>
          </Styled.OfferUnknown>
        </Styled.OfferContainer>
      )}
    </>
  );
};

const LoadingOffer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noOffers}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="primary" size={40} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferManager;
