import Field from 'components/Field';
import React, { useEffect, useState } from 'react';
import nanoid from 'utils/nanoid';

import {
  AudienceGroup,
  ToggleGroupNameDialogAction,
  createGroup,
  setGroupSavingState,
  toggleGroupNameDialog,
} from '../slice';
import { Box, Button, Dialog, Grid, theme } from '@klover/attain-design-system';
import { Formik } from 'formik';
import { SnackBarOptions, defaultSnackBarOptions } from 'interfaces/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

// Material UI Components
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as Styled from './index.styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    groupName: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 23,
      paddingLeft: 16,
      paddingTop: 16,
    },
  })
);

interface Props {
  open: boolean;
}

const GroupNameDialog = ({ open }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [name, setName] = useState('');
  const [snackBarOptions, setSnackBarOptions] = useState<SnackBarOptions>(
    defaultSnackBarOptions
  );

  const groupSaving = useAppSelector(
    (state) => state.audienceReducer.ui.groupSaving
  );

  useEffect(() => {
    let snackBarOptions: SnackBarOptions;
    switch (groupSaving.state) {
      case 'done':
        setName('');
        dispatchClose();

        snackBarOptions = {
          open: true,
          severity: 'success',
          message: groupSaving.message || '',
        };
        break;
      case 'failed':
        snackBarOptions = {
          open: true,
          severity: 'error',
          message: groupSaving.message || '',
        };
        break;
      default:
        snackBarOptions = {
          open: false,
          severity: 'success',
          message: '',
        };
        break;
    }
    setSnackBarOptions(snackBarOptions);
  }, [groupSaving]);

  const closeSnackBar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    const updatedSnackBarOptions = {
      ...snackBarOptions,
      open: false,
    };
    dispatch(setGroupSavingState({ state: 'idle' }));
    setSnackBarOptions(updatedSnackBarOptions);
  };

  const loading = groupSaving.state === 'loading';

  const dispatchClose = () => {
    const actionProps: ToggleGroupNameDialogAction = {
      open: false,
    };
    setSnackBarOptions(defaultSnackBarOptions);
    dispatch(toggleGroupNameDialog(actionProps));
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    setName(name);
  };

  const handleCreateGroup = () => {
    if (name && name !== '') {
      dispatch(createGroup(newGroup));
    } else {
      dispatch(
        setGroupSavingState({
          state: 'failed',
          message: 'Group name is required',
        })
      );
    }
  };

  const handleClose = () => {
    dispatchClose();
  };

  const isCreateDisabled = name === '';

  const newGroup: AudienceGroup = {
    id: nanoid(),
    name: name,
    audiences: [],
  };

  return (
    <>
      <Dialog
        fullWidth
        className={classes.root}
        title="New Group"
        onClose={handleClose}
        open={open}
        headerID="createAudienceGroupHeader"
        header={
          <Styled.CardHeader>
            <Styled.CardTitle>New Group</Styled.CardTitle>
          </Styled.CardHeader>
        }
        bodyID="createAudienceGroupBody"
        body={
          <Styled.StyledBodyContainer
            container
            spacing={theme.spacing.lg}
            direction="column"
          >
            <Grid item>
              <Styled.BodyHeader>
                How do you want to name the new group?
              </Styled.BodyHeader>
              <Formik>
                <Field
                  className={classes.groupName}
                  onChange={handleNameChange}
                  value={name}
                  fullWidth
                  id="name"
                  type="name"
                />
              </Formik>
            </Grid>
          </Styled.StyledBodyContainer>
        }
        footer={
          <DialogActions>
            <Button color="primary" onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            {loading ? (
              <Box
                width={142}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress color="primary" size={20} />
              </Box>
            ) : (
              <Button
                color="primary"
                disabled={isCreateDisabled}
                disableElevation
                onClick={handleCreateGroup}
                variant="contained"
              >
                Create Group
              </Button>
            )}
          </DialogActions>
        }
      ></Dialog>
      <Snackbar
        open={snackBarOptions.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity={snackBarOptions.severity}>
          {snackBarOptions.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupNameDialog;
